import React from "react";
import PageLayout from "cmp/PageLayout";

const NotFound = () => (
  <PageLayout>
    <h1 className="text-center">404, you lost</h1>
  </PageLayout>
);

export default NotFound;
