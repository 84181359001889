import React from "react";
import Table from "cmp/Table";
import TableLoadingPlaceholder from "cmp/TableLoadingPlaceholder";
import useSeats from "mod/useSeats";
import formatDate from "mod/formatDate";

const SeatsTable = () => {
  const { loading, seats, scheme } = useSeats();

  if (loading) return <TableLoadingPlaceholder />;

  return (
    <Table
      className="SeatsTable"
      scheme={scheme}
      keyProperty="id"
      data={seats.map(seat => ({
        ...seat,
        membership:
          seat.membership instanceof Date
            ? formatDate(seat.membership)
            : String(seat.membership || "")
      }))}
    />
  );
};

export default SeatsTable;
