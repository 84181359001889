import React from "react";
import NavBar from "cmp/NavBar";
import PageHeader from "cmp/PageHeader";

/**
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {string | undefined} [props.title]
 * @param {Array<{ label: string, callback: () => void, className?: string }> | undefined} [props.actions]
 */
const PageLayout = ({ children, title, actions }) => (
  <div id="seats-page" className="page seats-page">
    <NavBar />
    <div className="container my-4">
      {title ? <PageHeader title={title} actions={actions} /> : undefined}
      <main className="my-4">{children}</main>
    </div>
  </div>
);

export default PageLayout;
