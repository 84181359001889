import "./InputWithSuggestions.scss";
import React, { useState, useRef } from "react";
import reactTriggerChange from "react-trigger-change";

/**
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { values: Array<string>, value?: string }} inputProps
 */
const InputWithSuggestions = ({ values, ...inputProps }) => {
  const [value, setValue] = useState(inputProps.value || "");
  const [isInputFocused, setInputFocusedState] = useState(false);

  /** @type {React.MutableRefObject<HTMLInputElement | null>} */
  const inputRef = useRef(null);

  const focus = event => {
    setInputFocusedState(true);
    inputProps.onFocus && inputProps.onFocus(event);
  };
  const blur = event => {
    setTimeout(() => setInputFocusedState(false), 100);
    inputProps.onBlur && inputProps.onBlur(event);
  };

  const input = event => {
    setValue(event.target.value);
    inputProps.onInput && inputProps.onInput(event);
  };
  const change = event => {
    setValue(event.target.value);
    inputProps.onChange && inputProps.onChange(event);
  };

  const select = value => {
    if (!inputRef.current) return;
    inputRef.current.value = value;
    reactTriggerChange(inputRef.current);
  };

  const clearedValue = value && String(value).replace(/[^a-z]/gi, "");
  const regExp = clearedValue
    ? new RegExp(`${clearedValue.split("").join(".*")}`, "i")
    : /.*/;
  const filteredValues = values.filter(value => value.match(regExp));
  const availableOptions = filteredValues.concat(
    filteredValues.length ? [] : value
  );
  const isSuggestionsVisible = isInputFocused && availableOptions.length;

  const componentClasses = ["InputWithSuggestions"]
    .concat(isSuggestionsVisible ? "InputWithSuggestions__Focused" : [])
    .concat(inputProps.className || []);
  return (
    <div className={componentClasses.join(" ")}>
      <input
        {...inputProps}
        ref={inputRef}
        className="InputWithSuggestions_Input"
        type="search"
        autoComplete="off"
        value={value}
        onInput={input}
        onChange={change}
        onFocus={focus}
        onBlur={blur}
      />
      {isSuggestionsVisible ? (
        <ol className="InputWithSuggestions_Suggestions">
          {availableOptions.map(value => (
            <li
              key={value}
              className="InputWithSuggestions_SuggestionsItem"
              onClick={() => select(value)}
            >
              {value}
            </li>
          ))}
        </ol>
      ) : (
        undefined
      )}
    </div>
  );
};

export default InputWithSuggestions;
