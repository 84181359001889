import React from "react";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {Array<{ label: string, callback: () => void, className?: string }> | undefined} [props.actions]
 */
const PageHeader = ({ title, actions }) => (
  <header className="d-flex justify-content-between align-items-center w-100">
    <h1>{title}</h1>
    {actions && actions.length ? (
      <form className="form-inline">
        {actions.map(
          ({ label, callback, className = "btn-outline-primary" }) => (
            <button
              key={label}
              className={`btn btn-sm ml-2 ${className}`}
              onClick={callback}
              type="button"
            >
              {label}
            </button>
          )
        )}
      </form>
    ) : (
      undefined
    )}
  </header>
);

export default PageHeader;
