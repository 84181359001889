import React, { useEffect } from "react";
import Center from "cmp/Center";
import useUniqueId from "mod/useUniqueId";
import firebase from "mod/firebase";
import firebaseui from "mod/firebaseui";

/**
 * @param {string} formContainerSelector
 */
const renderAuthForm = formContainerSelector => {
  const uiConfig = {
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ]
  };

  const ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.start(formContainerSelector, uiConfig);

  return () => ui.delete();
};

const AuthenticationPage = () => {
  const id = useUniqueId("auth-form");
  useEffect(() => {
    // TODO: return function to destroy auth form
    renderAuthForm(`#${id}`);
  }, [id]);
  return (
    <Center>
      <div className="text-center">
        <h1>Seat Me</h1>
        <div id={id}></div>
      </div>
    </Center>
  );
};

export default AuthenticationPage;
