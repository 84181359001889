/**
 * @typedef {Array<{ label?: string, property?: string, render?: (data: any) => JSX.Element | null }>} TableScheme
 */

/** @type {TableScheme} */
const scheme = [
  { label: "#", property: "id" },
  { label: "Name", property: "name" },
  { label: "Seat", property: "seat" },
  { label: "Membership", property: "membership" }
];

export { scheme };
