import React from "react";
import useUserData from "mod/useUserData";
import WaitingOverlay from "cmp/WaitingOverlay";
import AuthenticationPage from "cmp/AuthenticationPage";
import convertToJSON from "mod/convertToJSON";

const RequireAuthentication = ({ children }) => {
  const [user, loading, error] = useUserData();

  if (loading) return <WaitingOverlay>Authenticating…</WaitingOverlay>;

  if (error)
    return (
      <WaitingOverlay>
        <strong>Cannot authenticate user</strong>
        <pre>{convertToJSON(error, null, 2)}</pre>
      </WaitingOverlay>
    );

  if (!user) return <AuthenticationPage />;

  return children;
};

export default RequireAuthentication;
