import "mod/bootstrap";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RequireAuthentication from "cmp/RequireAuthentication";
import HomePage from "cmp/HomePage";
import MembersPage from "cmp/MembersPage";
import SeatsPage from "cmp/SeatsPage";
import AddMemberPage from "cmp/AddMemberPage";
import AddSeatPage from "cmp/AddSeatPage";
import NotFoundPage from "cmp/NotFoundPage";

const App = () => {
  return (
    <RequireAuthentication>
      <Router>
        <div className="Application">
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>

            <Route path="/members" exact>
              <MembersPage />
            </Route>
            <Route path="/seats" exact>
              <SeatsPage />
            </Route>

            <Route path="/add-member" exact>
              <AddMemberPage />
            </Route>

            <Route path="/add-seat" exact>
              <AddSeatPage />
            </Route>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </RequireAuthentication>
  );
};

export default App;
