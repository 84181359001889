import { useState } from "react";
import qinu from "qinu";

const generateId = qinu.create({ template: `%arg[0]%-%qinu%` });

const useUniqueId = (prefix = "id") => {
  const [id] = useState(() => generateId(prefix));
  return id;
};

export default useUniqueId;
