import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "cmp/PageLayout";
import useFirestoreCollection from "mod/useFirestoreCollection";
import MemberForm from "cmp/MemberForm";

const AddMemberPage = ({ history }) => {
  const { add } = useFirestoreCollection("app/members/list");

  const submit = async (formData) => {
    try {
      await add(formData);
      history.push("/members");
    } catch (error) {
      console.error("firestore::add() failed", error);
      alert("Could not process save operation");
    }
  };

  return (
    <PageLayout
      title="Add new member"
      actions={[
        {
          label: "View members",
          className: "btn-outline-secondary",
          callback: () => history.push("/members"),
        },
      ]}
    >
      <MemberForm onSubmit={submit} />
    </PageLayout>
  );
};

export default withRouter(AddMemberPage);
