import formatDate from "mod/formatDate";

/**
 * @param {Date | number} membership
 */
const formatMembership = membership => {
  if (membership instanceof Date) return formatDate(membership);
  return `${membership} days left`;
};

export default formatMembership;
