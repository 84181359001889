import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "cmp/PageLayout";
import MembersTable from "cmp/MembersTable";

const MembersPage = ({ history }) => {
  return (
    <PageLayout
      title="Members"
      actions={[
        {
          label: "Add new member",
          callback: () => history.push("/add-member")
        }
      ]}
    >
      <MembersTable />
    </PageLayout>
  );
};

export default withRouter(MembersPage);
