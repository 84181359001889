import React from "react";
import Form from "cmp/Form";
import InputWithSuggestions from "cmp/InputWithSuggestions";

const SAMPLE = ["Office 1", "Office 2"].sort();

const SeatForm = ({ onSubmit }) => (
  <Form onSubmit={onSubmit}>
    <div className="form-group row">
      <label htmlFor="seat-label" className="col-md-3 col-form-label">
        Label <span className="text-danger">*</span>
      </label>
      <div className="col-md-9">
        <input
          name="label"
          type="text"
          className="form-control"
          id="seat-label"
          required
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="seat-price" className="col-md-3 col-form-label">
        Price <span className="text-danger">*</span>
      </label>
      <div className="col-md-9">
        <input
          name="price"
          type="number"
          min="0"
          className="form-control"
          id="seat-price"
          required
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="seat-location" className="col-md-3 col-form-label">
        Location
      </label>
      <div className="col-md-9">
        <input
          name="location"
          type="text"
          className="form-control"
          id="seat-location"
          required
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="seat-group" className="col-md-3 col-form-label">
        Group
      </label>
      <div className="col-md-9">
        <InputWithSuggestions
          values={SAMPLE}
          name="group"
          type="search"
          className="form-control"
          id="seat-group"
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-9 offset-md-3">
        <button type="submit" className="btn btn-primary">
          Create new seat
        </button>
      </div>
    </div>
  </Form>
);

export default SeatForm;
