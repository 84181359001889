/**
 * @param {any} value
 * @param {((this: any, key: string, value: any) => any) | null | undefined} [replacer]
 * @param {string | number | undefined} [space]
 * @returns {string}
 */
const convertToJSON = (value, replacer, space) =>
  JSON.stringify(value, replacer || undefined, space);

export default convertToJSON;
