import "./TableLoadingPlaceholder.scss";
import React from "react";

const Row = ({ of: Cell }) => (
  <tr className="TableLoadingPlaceholder_Row">
    <Cell className="TableLoadingPlaceholder_Cell">
      <div className="TableLoadingPlaceholder_TextLine"></div>
    </Cell>
    <Cell className="TableLoadingPlaceholder_Cell">
      <div className="TableLoadingPlaceholder_TextLine"></div>
    </Cell>
    <Cell className="TableLoadingPlaceholder_Cell">
      <div className="TableLoadingPlaceholder_TextLine"></div>
    </Cell>
  </tr>
);

const TableLoadingPlaceholder = ({ text = "Wait" }) => (
  <div className="TableLoadingPlaceholder table-responsive">
    <table className="table table-hover">
      <thead>
        <Row of="th" />
      </thead>
      <tbody>
        <Row of="td" />
        <Row of="td" />
      </tbody>
    </table>
  </div>
);

export default TableLoadingPlaceholder;
