import { scheme } from "mod/members";
import useFirebaseCollection from "mod/useFirestoreCollection";

/** @typedef {Array<{ id: string, name: string, seat?: string, membership?: number | Date }>} MembersList */

/**
 * @typedef {Array<{ label?: string, property?: string, render?: (data: any) => JSX.Element | null }>} TableScheme
 */

/**
 * @param {boolean} immediate Start loading on execution
 * @returns {{ loading: boolean, members: MembersList, scheme: TableScheme, load: () => void }}
 */
const useMembers = (immediate = true) => {
  const { documents, load, loading } = useFirebaseCollection(
    "app/members/list",
    immediate
  );
  const members = documents.map((doc, index) => ({
    ...doc.data(),
    id: index + 1
  }));
  return { loading, members, scheme, load };
};

export default useMembers;
