import React from "react";
import convertToJSON from "mod/convertToJSON";
import EmptyTable from "cmp/EmptyTable";

/**
 * @typedef {Array<{ label?: string, property?: string, render?: (data: any) => JSX.Element | null }>} TableScheme
 */

/**
 * @param {Array<{ [key: string]: string | undefined }>} data
 * @param {TableScheme} [scheme]
 * @returns {TableScheme}
 */
const getColumns = (data, scheme) => {
  if (scheme) return scheme;
  const extractedProps = new Set();
  data.forEach(item => {
    Object.keys(item).forEach(key => extractedProps.add(key));
  });
  return [...extractedProps].map(property => ({ property, label: property }));
};

const generateUniqKey = (object, index) => `${index}-${convertToJSON(object)}`;

/**
 * @param {object} props
 * @param {Array<{ [key: string]: string | undefined }>} props.data
 * @param {TableScheme} [props.scheme]
 * @param {string} [props.keyProperty]
 * @param {string} [props.placeholder]
 * @param {string} [props.className]
 */
const Table = ({ data, scheme, keyProperty, className }) => {
  if (!data.length) return <EmptyTable />;

  const columns = getColumns(data, scheme);
  if (!columns.length) {
    console.error("Table requires scheme to have at least on column");
    return null;
  }

  return (
    <div className={`table-responsive ${className || ""}`}>
      <table className="table table-hover">
        <thead>
          <tr>
            {columns.map(({ label }, index) => (
              <th key={index} scope="col">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={
                keyProperty ? item[keyProperty] : generateUniqKey(item, index)
              }
              tabIndex={0}
            >
              {columns.map(({ property, render }, index) => (
                <td key={index}>
                  {render ? render(item) : (property && item[property]) || "—"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
