import React, { useState } from "react";
import PropTypes from "prop-types";
import parseFormInput from "mod/parseFormInput";

/**
 * @typedef {(values: { [name: string]: string }) => void} onSubmitProperty
 * @typedef {(name: string, value: string | boolean, previousValue?: string | boolean) => void} onInputProperty
 * @typedef {(name: string, value: string | boolean, previousValue?: string | boolean) => void} onChangeProperty
 * @typedef {(values: { [name: string]: string }) => void} onUpdateProperty
 * @param {React.Props & { onSubmit: onSubmitProperty, onChange?: onChangeProperty, onUpdate?: onUpdateProperty }} props
 */
const Form = ({ children, onSubmit, onChange, onUpdate }) => {
  const [values, update] = useState({});

  const submit = event => {
    event.preventDefault();
    onSubmit(values);
  };

  const change = ({ target }) => {
    if (!target.name) return;

    const { name, value } = parseFormInput(target);
    onChange && onChange(name, value, values[name]);

    const newValues = { ...values, [name]: value };
    update(newValues);
    onUpdate && onUpdate(newValues);
  };

  return (
    <form onSubmit={submit} onChange={change}>
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func
};

export default Form;
