/**
 * @param {Date} date
 */
const formatDate = date => {
  const [month, day, year] = date
    .toString()
    .split(" ")
    .slice(1, 4);
  return `${month} ${day}, ${year}`;
};

export default formatDate;
