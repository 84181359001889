import React from "react";
import { Link, withRouter } from "react-router-dom";
import useUserData from "mod/useUserData";
import firebase from "mod/firebase";

const signOut = () => firebase.auth().signOut();

const NavLink = ({ path, children, location }) => (
  <li className={`nav-item ${location.pathname === path ? "active" : ""}`}>
    <Link to={path} className="nav-link">
      {children}
    </Link>
  </li>
);

const brandName = "Brand";

const NavBar = ({ location }) => {
  const [user] = useUserData();
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src="/icons/favicon-96x96.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt={brandName}
            style={{ background: "#fff", borderRadius: "18%", padding: "2%" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample07"
          aria-controls="navbarsExample07"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExample07">
          <ul className="navbar-nav mr-auto">
            <NavLink path="/members" location={location}>
              Members
            </NavLink>
            <NavLink path="/seats" location={location}>
              Seats
            </NavLink>
          </ul>
          {user ? (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user.displayName}
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item disabled" href="/">
                    {brandName} (current)
                  </a>
                  <div className="dropdown-divider"></div>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={signOut}
                  >
                    Sign out
                  </button>
                </div>
              </li>
            </ul>
          ) : (
            undefined
          )}
        </div>
      </div>
    </nav>
  );
};

export default withRouter(NavBar);
