import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "cmp/PageLayout";
import useFirestoreCollection from "mod/useFirestoreCollection";
import SeatForm from "cmp/SeatForm";

const AddSeatPage = ({ history }) => {
  const { add } = useFirestoreCollection("app/seats/list");

  const submit = async formData => {
    try {
      await add(formData);
      history.push("/seats");
    } catch (error) {
      console.error("firestore::add() failed", error);
      alert("Could not process save operation");
    }
  };

  return (
    <PageLayout
      title="Add new seat"
      actions={[
        {
          label: "View seats",
          className: "btn-outline-secondary",
          callback: () => history.push("/seats")
        }
      ]}
    >
      <SeatForm onSubmit={submit} />
    </PageLayout>
  );
};

export default withRouter(AddSeatPage);
