import React from "react";
import PageLayout from "cmp/PageLayout";

const HomePage = () => (
  <PageLayout>
    <p>Probably, dashboard will be here</p>
  </PageLayout>
);

export default HomePage;
