import React from "react";
import Table from "cmp/Table";
import MemberActions from "cmp/MemberActions";
import TableLoadingPlaceholder from "cmp/TableLoadingPlaceholder";
import useMembers from "mod/useMembers";
import formatMembership from "mod/formatMembership";

const MembersTable = () => {
  const { loading, members, scheme } = useMembers();

  if (loading) return <TableLoadingPlaceholder />;

  return (
    <Table
      className="MembersTable"
      scheme={scheme.concat({
        render: member => <MemberActions member={member} />
      })}
      keyProperty="id"
      data={members.map(member => ({
        ...member,
        membership:
          typeof member.membership !== "undefined"
            ? formatMembership(member.membership)
            : undefined
      }))}
    />
  );
};

export default MembersTable;
