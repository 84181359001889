import React from "react";
import Form from "cmp/Form";
import InputWithSuggestions from "cmp/InputWithSuggestions";

const SAMPLE = ["Org B", "Org A"].sort();

const MemberForm = ({ onSubmit }) => (
  <Form onSubmit={onSubmit}>
    <div className="form-group row">
      <label htmlFor="member-name" className="col-md-3 col-form-label">
        Name <span className="text-danger">*</span>
      </label>
      <div className="col-md-9">
        <input
          name="name"
          type="text"
          className="form-control"
          id="member-name"
          required
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="member-tel" className="col-md-3 col-form-label">
        Phone number <span className="text-danger">*</span>
      </label>
      <div className="col-md-9">
        <input
          name="tel"
          type="tel"
          className="form-control"
          id="member-tel"
          required
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="member-balance" className="col-md-3 col-form-label">
        Initial balance
      </label>
      <div className="col-md-9">
        <input
          name="balance"
          type="number"
          min="0"
          className="form-control"
          id="member-balance"
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="member-organization" className="col-md-3 col-form-label">
        Organization
      </label>
      <div className="col-md-9">
        <InputWithSuggestions
          values={SAMPLE}
          name="organization"
          type="search"
          className="form-control"
          id="member-organization"
        />
      </div>
    </div>

    <div className="form-group form-check">
      <div className="col-md-9 offset-md-3">
        <input
          name="isPayer"
          type="checkbox"
          className="form-check-input"
          id="member-payer"
        />
        <label className="form-check-label" htmlFor="member-payer">
          Payer
        </label>
      </div>
    </div>

    <div className="row">
      <div className="col-md-9 offset-md-3">
        <button type="submit" className="btn btn-primary">
          Create new member
        </button>
      </div>
    </div>
  </Form>
);

export default MemberForm;
