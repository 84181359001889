import React from "react";

const EmptyTable = () => (
  <div className="table-responsive">
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="text-center">No data</th>
        </tr>
      </thead>
    </table>
  </div>
);

export default EmptyTable;
