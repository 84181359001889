import React from "react";

const MemberActions = ({ member }) => (
  <div className="MemberActions text-right">
    <button className="btn btn-sm btn-outline-secondary ml-2">edit</button>
    <button
      className="btn btn-sm btn-outline-danger ml-2"
      onClick={() =>
        window.confirm(`Are you sure you want to remove ${member.name}?`)
      }
    >
      remove
    </button>
  </div>
);

export default MemberActions;
