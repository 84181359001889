import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "cmp/PageLayout";
import SeatsTable from "cmp/SeatsTable";

const SeatsPage = ({ history }) => {
  return (
    <PageLayout
      title="Seats"
      actions={[
        {
          label: "Add new seat",
          callback: () => history.push("/add-seat")
        }
      ]}
    >
      <SeatsTable />
    </PageLayout>
  );
};

export default withRouter(SeatsPage);
